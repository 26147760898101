.project {
    display: grid;
    width: 80%;
    background-color: lightgrey;
    margin-left: auto;
    margin-right: auto;
}

h4 {
    font-family: 'Press Start 2P', sans-serif;
    color: #3e5be9;
    text-align: left;
    margin-top: 0.6em;
    margin-bottom: 0.4em;
}

h5 {
    text-align: left;
    margin: 0;
}

h6 {
    font-weight: 1;
    font-style: italic;
    text-align: left;
    margin: 0;
}

a {
    color: black;
    text-decoration: none;
}

a:hover, a:active {
    text-decoration: underline;
}

.projectDescription {
    text-align: left;
    margin-top: 0.4em;
    margin-right: 1em;
    margin-left: 0;
}

.projectText {
    position: relative;
}

.featuredTag {
    position: absolute;
    top: 0;
    right: 0;
    font-family: 'Courier New', monospace;
    color: #d8217c;
    text-align: left;
}

.featuredStar {
    display: inline;
}

.featuredText {
    display: inline;
}

@media only screen and (max-width: 1560px) {
    .featuredText {
        display: none;
    }
}

/* Desktop */
@media only screen and (min-width: 415px) {
    .project {
        grid-template-columns: 1fr 3fr;
        min-width: 30em;
        margin-bottom: 1.5em;
    }

    h4 {
        font-size: 1.5em;
    }

    h5 {
        font-size: 1em;
    }

    h6 {
        font-size: 1em;
    }

    .projectImage {
        margin: 1em;
    }

    .projectDescription {
        font-size: 0.8em;
        margin-bottom: 1em;
    }

    .featuredTag {
        font-size: 1em;
        margin-top: 0.2em;
        margin-right: 0.5em;
    }
}

/* Mobile */
@media only screen and (max-width: 415px) {
    .project {
        grid-template-columns: 1fr 2fr;
        min-width: 12em;
        margin-bottom: 0.75em;
    }

    h4 {
        font-size: 0.75em;
    }

    h5 {
        font-size: 0.5em;
    }

    h6 {
        font-size: 0.5em;
    }

    .projectImage {
        width: 90%;
        margin: 0.2em;
    }

    .projectDescription {
        font-size: 0.3em;
        margin-bottom: 1em;
    }
    
    .featuredTag {
        font-size: 1em;
        margin-top: 0;
        margin-right: 0.4em;
    }
}