@import url('https://fonts.googleapis.com/css?family=Press+Start+2P');
@import url('https://fonts.googleapis.com/css?family=Open+Sans');

h1 {
    font-family: 'Press Start 2P', sans-serif;
    color: white;
}

h2 {
    font-family: 'Press Start 2P', sans-serif;
}

body {
    background-image: linear-gradient(#3e5be9, #ff85c2);
    background-attachment: fixed;
    text-align: center;
    font-family: 'Open Sans', Verdana, Geneva, Tahoma, sans-serif;
    color: black;
    overflow-x: hidden;
}

img {
    vertical-align: middle;
}

/* Desktop */
@media only screen and (min-width: 415px) {
    h1 {
        font-size: 2em;
    }

    h2 {
        font-size: 1.5em;
    }

    h3 {
        font-size: 1.25em;
    }

    body {
        min-width: 30em;
        font-size: 28px;
        margin-top: 10%;
    }

    .dropShadow {
        box-shadow: 20px 20px 0 rgba(0,0,0,0.3);
    }
}

/* Mobile */
@media only screen and (max-width: 415px) {
    h1 {
        font-size: 1em;
    }

    h2 {
        font-size: 0.75em;
    }

    h3 {
        font-size: 0.6em;
    }

    body {
        font-size: 24px;
        margin-top: 5%;
    }

    .dropShadow {
        box-shadow: 10px 10px 0 rgba(0,0,0,0.3);
    }
}