.navbar {
    position: absolute;
}

@media only screen and (max-width: 1250px) {
    .navbar {
        position: static;
    }
}

li {
    display: inline-block;
    font-family: 'Press Start 2P', sans-serif;
    list-style: none;
    color: white;
    transition: all 500ms;
}

.link:hover, .link:active {
    text-decoration: none;
}

li:hover, li:active {
    transform: translateY(-50%);
}

/* Desktop */
@media only screen and (min-width: 415px) {
    .navbar {
        min-width: 30em;
        top: 3em;
        right: 2em;
    }

    li {
        font-size: 1.25em;
    }

    li.rightMargin {
        margin-right: 3em;
    }
}

/* Mobile */
@media only screen and (max-width: 415px) {
    .navbar {
        top: 1.5em;
        right: 1em;
    }

    li {
        font-size: 0.6em;
    }

    li.rightMargin {
        margin-right: 2em;
    }
}