.aboutBlock {
    justify-content: center;
}

.emailAddress {
    color: white;
}

.bio {
    text-align: center;
}

a:hover, a:active {
    text-decoration: underline;
}

/* Desktop */
@media only screen and (min-width: 415px) {
    .aboutBlock {
        margin-left: 15em;
        margin-right: 15em;
    }
}

/* Mobile */
@media only screen and (max-width: 415px) {
    .bio {
        font-size: 0.7em;
    }

    .aboutBlock {
        margin-left: 1em;
        margin-right: 1em;
    }
}