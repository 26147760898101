.homeInlineBlock {
    display: inline-block;
    vertical-align: middle;
    margin-top: 5%;
}

@media only screen and (min-width: 1400px) {
    .leftBlock {
        margin-right: 3em;
    }
}

a {
    color: black;
    text-decoration: none;
}

a:hover, a:active {
    text-decoration: underline;
}

/* Mobile */
@media only screen and (max-width: 415px) {
    #computerGif {
        width: 70%;
    }
}