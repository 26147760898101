.block {
    display: block;
}

img {
    margin-right: 0.25em;
}

/* Desktop */
@media only screen and (min-width: 415px) {
    .block {
        margin: 0.25em;
    }
}

/* Mobile */
@media only screen and (max-width: 415px) {
    .block {
        margin: 0;
    }

    #linkImg {
        width: 0.8em;
    }

    #linkText {
        font-size: 0.8em;
    }
}